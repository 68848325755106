import './scripts/common';
import './scripts/common-nav';

let _config = window['_config'] || {};

// @ts-ignore
window.app = window['app'] || {
    debug: false,
};

// Todo: Add page specific JS here e.g:
import * as lightbox from '@elements/lightbox';
lightbox.init();

import * as lazyIframe from '@elements/lazy-iframe';
lazyIframe.init();

import * as scrollSlider from '@elements/scroll-slider';
scrollSlider.init({
    arrowIconClass:"icon icon-chevron",
    slides: 3,
});

function dotsDefaultRenderFunction(id = null, dotContainer, options) {
    let dotNamesArray = JSON.parse(dotContainer.dataset.imgTextSliderDotNames);
    let markupElement = document.createElement('div'),
        dotMarkup = `<button type="button" class="img-text-slider__dot js-scroll-slider__dots-btn ${id === 0 ? 'is-active' : '' }"><span class="scroll-slider__dot-text">${dotNamesArray[id]}</span></button>`;
    markupElement.innerHTML = dotMarkup;
    let newDot = markupElement.childNodes[0];
    dotContainer.appendChild(newDot);

    return newDot;
}

scrollSlider.init({
    arrowIconClass:"icon icon-chevron",
    dots: true,
    dotsRenderFunction: dotsDefaultRenderFunction
}, {
    base: '.js-img-text-slider',
    area: '.js-img-text-slider__area',
    item: '.js-img-text-slider__item',
    dotsContainer: '.js-img-text-slider__dots-container'
});
